<template>
  <v-main>
    <v-container>
      <h1 class="display-1">Taxes</h1>
      <v-dialog v-model="cousinCroak" max-width="700">
        <v-card class="mx-auto">
          <v-card-title>
            About Cousin Croak
            <v-spacer></v-spacer>
            <v-btn icon @click="cousinCroak = false"
              ><v-icon>mdi-close</v-icon></v-btn
            ></v-card-title
          >
          <v-container>
            <croak />
          </v-container>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogs.cpi" max-width="500">
        <cpi-description @close="dialogs.cpi = false"></cpi-description>
      </v-dialog>
      <v-row>
        <v-col cols="12" lg="12" class="peeps--font">
          Hi, <i>Peeps</i> here:<br />
          <p>
            One of my Truro friends, who is good with numbers and financial
            matters, was recently saying to me that she was feeling a little bit
            like my poor
            <span
              class="primary--text cursor-pointer"
              @click="cousinCroak = !cousinCroak"
              >cousin Croak</span
            >. Her taxes feel like they have been going up slowly over time,
            where she too feels like she’s a frog in a pot of water where the
            temperature goes slowly up.
          </p>
          <p>
            That got me thinking about Croak again, so I decided to do a little
            research. I took a building lot with nothing on it and researched
            its property taxes for the past 20 years. Since there is no house on
            the lot, the valuation would not change because of an addition or
            some other improvement, so this seemed to provide a good example to
            use to isolate the increase in taxes due to the tax rate alone.
          </p>
          <p>
            I then used the national
            <a href="https://www.bls.gov/data/" target="_blank"
              >Bureau of Labor Statistics
            </a>
            consumer price index
            <span
              class="cursor-pointer primary--text"
              @click="dialogs.cpi = true"
            >
              <u>(CPI-U)</u></span
            >
            for the Northeast as a measure of inflation and recorded and graphed
            that as well. And then I plotted both the taxes and the inflation
            measure, CPI-U, on a graph together so I could see them
            side-by-side.
          </p>
          <p>
            The graph below shows the increase since the year 2000 in both Truro
            taxes and inflation as measured above. A dotted trendline was added
            to the tax graph so you can see the trend more clearly.
          </p>

          <p>
            My takeaway is that Truro taxes have over the past two decades been
            growing four times faster than the rate of inflation. (approximately
            200% increase vs. a 50% increase). Granted this is just a sample of
            one property, but the best sample you can take is to see what is
            happening to
            <router-link to="/parcels">your own property taxes</router-link>.
          </p>
        </v-col>
        <v-col cols="12" lg="12">
          <v-img src="@/assets/img/TaxVSInflation.png"></v-img>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import Croak from '@/components/Croak'
export default {
  metaInfo: {
    title: 'About Taxes'
  },
  components: {
    Croak,
    CpiDescription: () => import('@/components/CPIDescription')
  },
  data: () => ({
    cousinCroak: false,
    dialogs: {
      cpi: false
    }
  }),
  methods: {
    showAboutPeeps() {
      this.$router.push('/about/peeps')
    }
  }
}
</script>

<style></style>
