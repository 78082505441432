<template>
  <v-card class="mx-auto default--font" hover>
    <v-card-title>
      <u class="mx-auto">
        <h2 class="text-center boiling-title">The Boiling Frog</h2>
      </u>
    </v-card-title>

    <v-container>
      The boiling frog is a fable describing a frog being slowly boiled alive.
      The premise is that if a frog is put suddenly into boiling water, it will
      jump out, but if the frog is put in tepid water which is then brought to a
      boil slowly, it will not perceive the danger and will be cooked to death.
      <div>
        <v-img
          class="frog-in-pot mx-auto"
          width="300"
          src="@/assets/img/FrogInPot.png"
        ></v-img>
      </div>
    </v-container>
  </v-card>
</template>

<script>
export default {}
</script>

<style></style>
