<template>
  <div class="peeps--font">
    <div>
      <p>
        My poor cousin <i>Croak</i>, who lives up Cape, is kind of oblivious to
        his environment and how it is slowly being damaged, while his taxes and
        living costs keep going up. And you can see where that has gotten him.
      </p>
    </div>
    <v-row>
      <v-col cols="12" sm="12" md="10" class="mx-auto mt-3 mb-3">
        <boiling-frog></boiling-frog>
      </v-col>
    </v-row>
    <div>
      <p>
        I’ve learned how to avoid his predicament, and I’m excited about sharing
        what I’ve learned with you. Please don’t be a Croak!
      </p>
    </div>
  </div>
</template>

<script>
import BoilingFrog from '@/components/BoilingFrog'
export default {
  components: {
    BoilingFrog
  }
}
</script>

<style></style>
